import React, {useRef, useState} from "react";
import {
    Contact,
    convertToFloorplanCardData,
    DriveGallery,
    FeatureSection,
    FloorplanSectionSkeleton,
    FloorplansSection,
    HomePageSpecialSection,
    PropertyDetails,
    PropertyLocation,
    sortFloorplans,
    TeamSection,
    trackContactClicked
} from "@renaissancerentals/renaissance-component";
import {HeroSection} from "../components/hero/HeroSection";
import {AmenitiesSection} from "../components/amenities/AmenitiesSection";
import {Button} from "@contentmunch/muncher-ui";
import {SecondaryFeature} from "../components/feature/SecondaryFeature";
import {TertiaryFeature} from "../components/feature/TertiaryFeature";
import "./assets/Home.scss";
import {MainLayout} from "../components/layout/MainLayout";
import {propertyId} from "../services/DataService";


export const Home: React.FC<PageProps> = ({property, isLoading, contact}) => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const floorPlanRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);

    const handleRefToFloorPlan = () => {
        floorPlanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };

    return (
        <MainLayout handleRefToFloorPlan={handleRefToFloorPlan} isHomePage={true}
                    handleRefToMap={handleRefToMap} isMainPage={true}
                    showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                    setShowApplicationModal={setShowApplicationModal}
                    setShowContactModal={setShowContactModal}
                    contact={contact} property={property}
        >
            <HomePageSpecialSection propertyId={property.id}/>
            <HeroSection property={property}/>
            <FeatureSection>
                <div className="feature">
                    <h2>Apartments & Townhomes</h2>
                    <h3>for rent</h3>
                    <p>Verona Park offers luxury flats, townhomes, and villas that are uniquely designed with modern
                        finishes, beautiful views, and real antique pieces throughout the residences.</p>
                    <a href="/apartments"><Button variant="primary"> Learn more&nbsp;&nbsp;››</Button></a>
                </div>
                <div className="feature">
                    <h2>Single Family Houses & Home Lots</h2>
                    <h3>for sale</h3>
                    <p>Build your dream home in our idyllic and quiet neighborhood filled with beautiful views,
                        gathering spaces, and walking trails. Family and pet-friendly, our home lots are yours for
                        the taking.</p>
                    <a href="/houses"><Button variant="primary">Learn more&nbsp;&nbsp;››</Button></a>
                </div>
                <div className="feature">
                    <h2>Commercial & Retail Space</h2>
                    <h3>for rent</h3>
                    <p>Looking for a community-centered location for your office, restaurant, or business? Verona
                        Park offers a limited amount of commercial spaces to enhance the neighborhood and
                        Bloomington as a whole. </p>
                    <a href="/retail"><Button variant="primary">Learn more&nbsp;&nbsp;››</Button></a>
                </div>

            </FeatureSection>
            <SecondaryFeature/>
            <TertiaryFeature handleRefToMap={handleRefToMap}
                             handleRefToContact={() => {
                                 setShowContactModal(true);
                                 trackContactClicked("verona-park");
                             }}/>
            <AmenitiesSection heading="Neighborhood Amenities"/>

            <div className="container">
                {isLoading ? "" :
                    <DriveGallery
                        driveId={property.photosFolderId}
                        type="simple"
                        showName={false}
                        initialSize={15}
                        propertyId={propertyId}
                    />}
            </div>
            <div ref={floorPlanRef} id="floorplan" className="reference">
            </div>
            {isLoading ?
                <FloorplanSectionSkeleton/> :
                <>{property?.floorplans ? <FloorplansSection
                    propertyId={propertyId}
                    floorplans={
                        sortFloorplans(
                            property.floorplans.filter(floorplan => floorplan.active)
                                .map(floorplan => convertToFloorplanCardData(floorplan)
                                ), "featured")
                    }/> : ""}

                </>
            }

            <div ref={mapRef} id="location" className="reference">
            </div>
            <div className="container">
                <h2 className="heading"><span className="emphasized">Location</span></h2>
            </div>
            <PropertyLocation property={property} isLoading={isLoading}
                              handleRefToContact={() => {
                                  setShowContactModal(true);
                                  trackContactClicked("verona-park");
                              }}/>


            <TeamSection isLoading={isLoading} teamMembers={property.teamMembers} propertyId={propertyId}/>

        </MainLayout>
    );
};


export interface PageProps {
    isLoading: boolean;
    property: PropertyDetails;
    contact: Contact;
}
